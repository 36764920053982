import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {currentUser, parseSaveImage, parseSaveObject} from "../../api/parseApi"
import {AppThunk} from "../../store/store"
import {notification} from "../notification/notificationSlice"

interface MarkeplaceState {
  loading: boolean;
  error: string;
}

const initialState: MarkeplaceState = {
  loading: false,
  error: ""
}

const slice = createSlice({
  name: "marketplace",
  initialState,
  reducers: {
    saveItemStart: (state: MarkeplaceState) => {
      state.loading = true
      state.error = ""
    },
    saveItemError: (state: MarkeplaceState, {payload}: PayloadAction<string>) => {
      state.loading = false
      state.error = payload
    },
    saveItemSuccess: (state: MarkeplaceState) => {
      state.loading = false
      state.error = ""
    }
  }
})

export const addGameItemPending = (item: any): AppThunk => async (
  dispatch
) => {
  try {
    const addedBy = await currentUser()
    if (!addedBy || !addedBy.authenticated()) {
      dispatch(slice.actions.saveItemError("Please login to create a Marketplace Item"))
      dispatch(notification("Please login to create a Marketplace Item!", "warning"))
      return
    }
    dispatch(slice.actions.saveItemStart())
    const images = await Promise.all(
      item.images.map((image: any) => {
        return parseSaveImage(image.name, image)
      })
    )
    const authorImage = await parseSaveImage(item.authorImage.name, item.authorImage)
    const toUpload = {...item, images, addedBy, authorImage}
    const savedObject = await parseSaveObject("MarketplaceItemPending", toUpload)
    dispatch(slice.actions.saveItemSuccess())
    dispatch(notification(`Thank you for your contribution ${addedBy.getUsername()}!`, "success"))
  } catch (err) {
    dispatch(slice.actions.saveItemError(err.toString()))
    dispatch(notification(`Unable to create Marketplace Item: ${err.toString()}`, "warning"))
  }
}

export default slice.reducer
