module.exports = [{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Recalbox","short_name":"Recalbox","start_url":"/","background_color":"#161b28","theme_color":"#161b28","display":"standalone","icon":"src/data/images/recalbox-white-512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1e9299f6d64f62322575565d25eadee5"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"path":"./src/locales","languages":["en","fr","pt","it","de","es"],"defaultLanguage":"en","fallbackLng":"en","siteUrl":"https://www.recalbox.com/","i18nextOptions":{"interpolation":{"escapeValue":false},"fallbackLng":"en","returnEmptyString":false,"keySeparator":false,"nsSeparator":"^","preload":["en","fr","pt","it","de","es"],"ns":["translation","languages","nav","faq","diy","site","home","database","download","navigation","legal","tutorials","rrgbdual","rrgbdmanual","rrgbdroadmap","rrgbdorder","patreon"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-557CRZ5","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com"},
    }]
