import {BadgeType} from "../features/badges/Badges"

import * as parseApi from "./parseApi"
import * as Parse from "parse"
import {UserGameParse, UserGameWithParse} from "./ParseObjects"

export const getUserSystems = async (): Promise<number[]> => {
  return parseApi.currentUser().then((user) => {
    return user.get("systems")
  })
}

export type RecalboxEvent<T extends EventData> =
  {
    sessionId: string,
    eventType: string,
    eventName: string,
    eventDate: Date,
    data: T
  }

export interface EventData {
}

export interface UserEvent {
  userName: string
}

export interface GameEvent extends EventData {
  sha1: string,
  name: string,
  source: "system",
  system: "nes",
}

export interface LevelEvent extends EventData {
  level: number
}

export interface BadgeEvent extends EventData {
  badge: BadgeType
}

export type TopicGame = { ssid: number, title: string, sha1: string }

export interface Image {
  url: string;
  name: string;
}

export interface User {
  name: string;
  email: string;
  avatar?: Image;
  background?: Image;
  online: boolean;
  levelPercent: number;
  activity: "web" | "playing" | "recalbox";
  roles?: string[]
}

// Todo in RecalboxUser
export const mapParseToUser = (doc: Parse.User, roles: string[]): User => ({
  name: doc.getUsername()!,
  email: doc.getEmail()!,
  avatar: doc.get("avatar") && {name: doc.get("avatar").name, url: doc.get("avatar").url()},
  background: doc.get("background") && {name: doc.get("background").name, url: doc.get("background").url()},
  online: true,
  levelPercent: 80,
  activity: Math.random() > 0.5 ? "playing" : "web",
  roles: roles,
})

export type Topic = {
  id: string,
  name: string,
  avatar: string,
  background: string,
  games: number[],
  users: User[],
  description: string,
}

export const getUserEvents = (limit: number): Promise<RecalboxEvent<EventData>[]> => {
  return Promise.resolve([{
    sessionId: "xxx",
    eventType: "game",
    eventName: "start",
    eventDate: new Date(),
    data: {
      sha1: "COFFEBABEAAAAAAAAAA",
      name: "Super Mario Bros 3",
      source: "system",
      system: "nes",
    }
  }, {
    sessionId: "xxx",
    eventType: "game",
    eventName: "start",
    eventDate: new Date(),
    data: {
      sha1: "COFFEBABEAAAAAAAAAA",
      name: "Zelda a link to the past",
      source: "system",
      system: "snes",
    }
  }])
}

export const getCurrentUserOnlineFriends = (limit: number): Promise<User[]> => {
  return parseApi.searchUsers("", limit).then(users => users.map(user => mapParseToUser(user, [])))
}

export const getFriendsEvents = (limit: number): Promise<RecalboxEvent<EventData & UserEvent>[]> => {
  return Promise.resolve([{
    sessionId: "xxx",
    eventType: "game",
    eventName: "start",
    eventDate: new Date(),
    data: {
      sha1: "COFFEBABEAAAAAAAAAA",
      name: "Super Mario Bros 3",
      source: "system",
      system: "nes",
      userName: "pit64",
    }
  }, {
    sessionId: "xxx",
    eventType: "game",
    eventName: "start",
    eventDate: new Date(),
    data: {
      sha1: "COFFEBABEAAAAAAAAAA",
      name: "Zelda a link to the past",
      source: "system",
      system: "snes",
      userName: "pit64",
    }
  }, {
    sessionId: "xxx",
    eventType: "level",
    eventName: "up",
    eventDate: new Date(),
    data: {
      userName: "david",
      level: 31
    }
  }, {
    sessionId: "xxx",
    eventType: "badge",
    eventName: "new",
    eventDate: new Date(),
    data: {
      userName: "bkg2k",
      badge: "twoplayers"
    }
  }])
}

export const getUserTopics = async (limit: number): Promise<Topic[]> => {
  const users = await parseApi.searchUsers("")
  return [{
    id: "1",
    name: "Street Fighter France",
    avatar: "http://beeimg.com/images/i47253524502.png",
    background: "https://wallpapermemory.com/uploads/524/street-fighter-wallpaper-hd-1080p-466487.jpg",
    games: [
      167336,
      167334],
    users: users.map(u => mapParseToUser(u, [])),
    description: "Jouez avec les plus grands fans de Street Fighter Français. Tous les Streets sont de la partie : du premier Street Fighter a Street Fighter 4 Third Strike !"
  },
    {
      id: "1",
      name: "Street Fighter 3",
      avatar: "https://www.fightersgeneration.com/news2020/game/streetfighter3-new-generation-logo.png",
      background: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTH3TumxxJ_RBFTsG5JNUe8K6KDvu8KlpBQ2w&usqp=CAU",
      games: [13894,
        37592,
        37593,
        37594,
        37595,
        37596,
        13879,
        37591,
        13857,
        13878,
        167335,
        167337,
        167334,
        167336
      ],
      users: users.map(u => mapParseToUser(u, [])),
      description: "Let's fight on Street Fighter 3. Street Fighter 3 ONLY and 4 ever!"

    },
    {
      id: "1",
      name: "Zelda",
      avatar: "https://static.wikia.nocookie.net/zelda_gamepedia_en/images/9/9c/TLoZ_Series_Triforce_Artwork.png/revision/latest/scale-to-width-down/320?cb=20190214050338",
      background: "https://i.pinimg.com/originals/bb/a2/21/bba221a826d09aa88e7768b51a115f08.jpg",
      games: [
        167336,
        167334],
      users: users.map(u => mapParseToUser(u, [])),
      description: "The Legend of Zelda will be our legend for ever. Watch live games and discover new zelda you might not know!"

    }]
}

export const findTopic = async (topicId: string): Promise<Topic> => {
  const users = await parseApi.searchUsers("")

  return {
    id: "1",
    name: "2D Fight Versus",
    avatar: "https://sebastechie.github.io/project-fighto/images/logo22.png",
    background: "https://i.pinimg.com/originals/93/f7/32/93f7326b49586070ca33f02fda1ab764.gif",
    games: [13894,
      37592,
      37593,
      37594,
      37595,
      37596,
      13879,
      37591,],
    users: users.map(u => mapParseToUser(u, [])),
    description: "Key kid! Do you like violence? Fight best rivals in this Topic about 2D Fight Versus games!"
  }
}

export const findTopics = async (search: string, limit: number): Promise<Topic[]> => {
  const users = await parseApi.searchUsers("")
  return [{
    id: "1",
    name: "2D Versus",
    avatar: "https://sebastechie.github.io/project-fighto/images/logo22.png",
    background: "https://i.pinimg.com/originals/93/f7/32/93f7326b49586070ca33f02fda1ab764.gif",
    games: [
      167336,
      167334],
    users: users.map(u => mapParseToUser(u, [])),
    description: "Do you like violence? Fight best rivals in this Topic about 2D Fight Versus games!"
  },
    {
      id: "1",
      name: "Tetris",
      avatar: "https://banner2.cleanpng.com/20180422/uhw/kisspng-tetris-app-tetris-blitz-android-electronic-arts-red-hot-air-balloon-5adc4264dcbb25.8231097215243843569041.jpg",
      background: "https://wallpapercave.com/wp/wp2675347.jpg",
      games: [
        167336,
        167334],
      users: users.map(u => mapParseToUser(u, [])),
      description: "You good with blocks ?"
    },
    {
      id: "1",
      name: "GameCube",
      avatar: "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/bcf11998-4dc5-4f5b-bddb-547affb31e39/d8g76n3-a3348824-8eb5-4e37-a16a-a6c866e7bdb3.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOiIsImlzcyI6InVybjphcHA6Iiwib2JqIjpbW3sicGF0aCI6IlwvZlwvYmNmMTE5OTgtNGRjNS00ZjViLWJkZGItNTQ3YWZmYjMxZTM5XC9kOGc3Nm4zLWEzMzQ4ODI0LThlYjUtNGUzNy1hMTZhLWE2Yzg2NmU3YmRiMy5wbmcifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6ZmlsZS5kb3dubG9hZCJdfQ.zKvQQElgeGKKoXstbdk-yjP6Vaxog25kC0_5xGgP8VI",
      background: "http://images2.fanpop.com/images/photos/5400000/Mario-Strikers-Charged-super-mario-bros-5430870-1024-768.jpg",
      games: [
        167336,
        167334],
      users: users.map(u => mapParseToUser(u, [])),
      description: "All related gamecube stuff! The cube will always be in our heart..."
    },
    {
      id: "1",
      name: "Vertical SHMUP",
      avatar: "https://chrismalnu.files.wordpress.com/2016/01/f-15-cipher-copy.png?w=680",
      background: "http://images2.fanpop.com/images/photos/5400000/Mario-Strikers-Charged-super-mario-bros-5430870-1024-768.jpg",
      games: [
        167336,
        167334],
      users: users.map(u => mapParseToUser(u, [])),
      description: "Engines ON! Let's flight together to the last boss!"
    }]
}

export const allTopics = (limit: number) => {
  return findTopics("", limit)
}

export type UserMessage = { user: User, message: string }

export const topicsMessages = async (topicId: string): Promise<UserMessage[]> => {
  const users = await parseApi.searchUsers("")
  const messages = ["Hello there", "Hi my fellow fighters!",
    "Who is ready for a fight?!",
    "Anyone knows how to unlock turbo mode on street fighter 2 turbo SNES ?",
    "I was running street fighter 3 third strike at 120 fps on rpi1, awesome!"]
  return users.map(user => ({
    user: mapParseToUser(user, []),
    message: messages[Math.floor(Math.random() * messages.length)]
  }))
}

export const getUserGames = async (system: number): Promise<UserGameWithParse[]> => {
  const query = new Parse.Query(UserGameParse)
  query.equalTo("system", system)
  const fetched = await query.find()
  return fetched.map(_ => _.attributes)
}
