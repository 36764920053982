import React from "react"
import {Provider, useDispatch} from "react-redux"
import store from "../../src/store/store"

import {notification} from "../../src/features/notification/notificationSlice"
import {restoreCurrentUser} from "../../src/features/profile/profileSlice"


export const onApplicationUpdate = () => {
  store.dispatch(notification("An update is available, please refresh the page.", "success"))
}

export default ({element}) => {
  store.dispatch(restoreCurrentUser())
  return <Provider store={store}>{element}</Provider>
}
