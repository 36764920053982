import Parse from "parse"
import {
  MarketplaceCategoryParse,
  MarketplaceCategoryWithParseImages,
  MarketplaceItemParse,
  MarketplaceItemWithParseImages,
  RecalboxUserEntity
} from "./ParseObjects"

Parse.initialize(process.env.GATSBY_PARSE_SERVER_APPLICATION_ID || "recalbox-testing")
Parse.serverURL = process.env.GATSBY_PARSE_URL || "http://localhost:1337/parse"

export interface ParseSaveSuccess {
  id: string;
}

export const getUserRoles = (user: Parse.User) : Promise<string[]> => {
  const queries = [
    new Parse.Query("_Role").equalTo("users", user)
  ]
  for (let i = 0; i < 2; i++) {
    queries.push(new Parse.Query("_Role").matchesQuery("roles", queries[i]))
  }

  return Parse.Query.or.apply(Parse.Query, queries).find().then(
    function (roles) {
      return roles.map(function (role) {
        return role.get("name")
      })
    }
  )
}

export async function parseLogin(username: string, password: string): Promise<RecalboxUserEntity> {
  const user = await RecalboxUserEntity.logIn<RecalboxUserEntity>(username, password)
  return user
}

export async function currentUser(): Promise<Parse.User> {
  const user = Parse.User.current()
  if (!user) throw new Error("User not connected")
  return user
}

export async function currentRecalboxUser(): Promise<RecalboxUserEntity> {
  const user = Parse.User.current<RecalboxUserEntity>()
  if (!user) throw new Error("User not connected")
  return user
}

export function isAuthenticated(): boolean {
  return Parse.User.current()?.authenticated() ?? false
}

export async function parseRegister(email: string, username: string, password: string): Promise<Parse.User> {
  const user = new Parse.User()
  user.set("username", username)
  user.set("password", password)
  user.set("email", email)
  return await user.signUp()
}

export async function parseSaveObject(className: string, object: any): Promise<ParseSaveSuccess> {
  const clazz = Parse.Object.extend(className)
  const item = new clazz(object)
  const saved = await item.save()
  return {id: item.id}
}

export async function parseSaveImage(name: string, image: File): Promise<Parse.File> {
  const parseFile = new Parse.File(name, image)
  return parseFile.save()
}

export async function searchUsers(search: string, limit: number = 30): Promise<Parse.User[]> {
  const query = new Parse.Query(Parse.User)
  if (search && search != "") {
    query.contains("username", search)
  }
  query.limit(limit)
  query.skip(0)
  return await query.find()
}

export async function findUser(userName: string): Promise<Parse.User | undefined> {
  const query = new Parse.Query(Parse.User)
  query.equalTo("username", userName)
  return await query.first()
}

export async function allMarketplaceCategories(): Promise<MarketplaceCategoryWithParseImages[]> {
  const query = new Parse.Query(MarketplaceCategoryParse)
  return await query.find().then(categories => categories.map((category) => {
    return category.attributes
  }))
}

export async function allMarketplaceItems(): Promise<MarketplaceItemWithParseImages[]> {
  const query = new Parse.Query(MarketplaceItemParse)
  return await query.find().then(items => items.map((item) => {
    return {...item.attributes, objectId: item.id}
  }))
}

export async function findMarketplaceCategory(name: string): Promise<MarketplaceCategoryWithParseImages | undefined> {
  const query = new Parse.Query(MarketplaceCategoryParse)
  query.equalTo("name", name)
  query.include("items")
  return query.first().then((category) => {
    return category?.attributes
  })
}

export async function findMarketplaceItems(category: string): Promise<MarketplaceItemWithParseImages[]> {
  const query = new Parse.Query(MarketplaceItemParse)
  query.equalTo("category", category)
  return await query.find().then(items => items.map((item) => {
    return {...item.attributes, objectId: item.id}
  }))
}

export async function findMarketplaceItem(itemId: string): Promise<MarketplaceItemWithParseImages> {
  const query = new Parse.Query(MarketplaceItemParse)
  return await query.get(itemId).then((item) => {
    return item.toMarketplaceItemWithParseImages()
  })
}
