import * as Parse from "parse"
import {File} from "../../graphql-types";

/* OMG sorry for that, this file is copied into parse-server for testing purpose
   Maybe tests should be here, we will se later.
   other imports than "parse/node" are forbidden
 */
export type ImageSharpFluid = {
  base64?: Maybe<Scalars["String"]>;
  tracedSVG?: Maybe<Scalars["String"]>;
  aspectRatio: Scalars["Float"];
  src: Scalars["String"];
  srcSet: Scalars["String"];
  srcWebp?: Maybe<Scalars["String"]>;
  srcSetWebp?: Maybe<Scalars["String"]>;
  sizes: Scalars["String"];
  originalImg?: Maybe<Scalars["String"]>;
  originalName?: Maybe<Scalars["String"]>;
  presentationWidth: Scalars["Int"];
  presentationHeight: Scalars["Int"];
};

export type GatsbyImageSharpFluidFragment = Pick<ImageSharpFluid, "base64" | "aspectRatio" | "src" | "srcSet" | "sizes">;

export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  JSON: any;
};
export type Maybe<T> = T | null;

export type ImageSharpFixed = {
  base64?: Maybe<Scalars["String"]>;
  tracedSVG?: Maybe<Scalars["String"]>;
  aspectRatio?: Maybe<Scalars["Float"]>;
  width: Scalars["Float"];
  height: Scalars["Float"];
  src: Scalars["String"];
  srcSet: Scalars["String"];
  srcWebp?: Maybe<Scalars["String"]>;
  srcSetWebp?: Maybe<Scalars["String"]>;
  originalName?: Maybe<Scalars["String"]>;
};


/* User */
export type RecalboxUserWithparse = { username: string, avatar: Parse.File, background: Parse.File, twitter: string, systems: number[] }

export class RecalboxUserEntity extends Parse.User<RecalboxUserWithparse> {
  static subClassName: string = "RecalboxUser"

  constructor(options: RecalboxUserWithparse) {
    super(options)
  }

  toUserWithParse() : RecalboxUserWithparse {
    return {...this.attributes}
  }
}

Parse.User.registerSubclass(RecalboxUserEntity.subClassName, RecalboxUserEntity)


/* Category */
export interface MarketplaceCategoryBase {
  name: string
  description: string
  url: string,
  items: any[]

}

export interface MarketplaceCategoryWithGatsbyImages extends MarketplaceCategoryBase {
  imagesType: "gatsby",
  localImages: Maybe<Array<Maybe<{ childImageSharp?: Maybe<{ fixed?: Maybe<Pick<ImageSharpFixed, "src">> }> }>>>
}

export interface MarketplaceCategoryWithParseImages extends MarketplaceCategoryBase {
  imagesType: "parse",
  images: Parse.File[],
  items: Parse.Object[],
}

export type Marketplace = MarketplaceCategoryWithGatsbyImages | MarketplaceCategoryWithParseImages

export class MarketplaceCategoryParse extends Parse.Object<MarketplaceCategoryWithParseImages> {
  constructor(options: MarketplaceCategoryWithParseImages) {
    super("MarketplaceCategory", options)
  }

  toMarketplaceCategoryWithParseImages() {
    return {...this.attributes, imagesType: "parse"}
  }
}

Parse.Object.registerSubclass("MarketplaceCategory", MarketplaceCategoryParse)

/* Item */
export interface MarketplaceItemBase {
  objectId: string,
  name: string
  description: string
  url: string
  category: string,
  authorName: string,
  mark: number,
  file: { url: string },
  createdAt: string,
  link: string,
}

export interface MarketplaceItemWithGatsbyImages extends MarketplaceItemBase {
  imagesType: "gatsby",
  localImages: Maybe<Array<Maybe<{ childImageSharp?: Maybe<{ fluid?: Maybe<GatsbyImageSharpFluidFragment> }> }>>>
  localAuthorImage: Maybe<{ childImageSharp?: Maybe<{ fixed?: Maybe<Pick<ImageSharpFixed, "src">> }> }>
}

export interface MarketplaceItemWithParseImages extends MarketplaceItemBase {
  imagesType: "parse",
  images: Parse.File[],
  authorImage: Parse.File,
  addedBy: Parse.User,
}

export type MarketplaceItem = MarketplaceItemWithGatsbyImages | MarketplaceItemWithParseImages

export class MarketplaceItemParse extends Parse.Object<MarketplaceItemWithParseImages> {
  constructor(options: MarketplaceItemWithParseImages) {
    super("MarketplaceItem", options)
  }

  toMarketplaceItemWithParseImages(): MarketplaceItemWithParseImages {
    return {...this.attributes, imagesType: "parse", objectId: this.id}
  }
}

Parse.Object.registerSubclass("MarketplaceItem", MarketplaceItemParse)


/* UserGame */
export type UserGameWithParse = { ssid: number, name: string, system: number, sha1?: string, addedBy: Parse.User }

export class UserGameParse extends Parse.Object<UserGameWithParse> {
  constructor(options: UserGameWithParse) {
    super("UserGame", options)
  }
}

Parse.Object.registerSubclass("UserGame", UserGameParse)


/* Blog */
export enum PlatformLanguage {
  "fr" = "fr",
  "en" = "en"
}

export type BlogPostState = "draft" | "published" | "deleted" | "review"

type BlogPostBase = {
  i18nId: string, state: BlogPostState, publishDate: Date, tags: string[],
  language: PlatformLanguage, title: string, content: string, original: boolean
}

export type BlogPostWithParse =
  BlogPostBase
  & { jumbo: Parse.File | undefined, images: Parse.File[], wroteBy: RecalboxUserEntity, translatedBy: RecalboxUserEntity | undefined }

export type BlogPostWithGatsby =
  BlogPostBase
  & {
  localJumbo: Maybe<File>,
  localImages: Maybe<Array<Maybe<File>>>,
  wroteBy: {
    username: string,
    twitter: string,
    avatar: {
      url: string
    }
  }
}

export type BlogPost = BlogPostWithParse | BlogPostWithGatsby
export const isBlogPostWithParse = (post: BlogPostBase): post is BlogPostWithParse => {
  return (post as BlogPostWithParse).images != undefined
}

export class BlogPostDraftEntity extends Parse.Object<BlogPostWithParse> {
  static subClassName: string = "DraftBlogPost"

  constructor(options: BlogPostWithParse) {
    super(BlogPostDraftEntity.subClassName, options)
  }
}

Parse.Object.registerSubclass(BlogPostDraftEntity.subClassName, BlogPostDraftEntity)

export class BlogPostEntity extends Parse.Object<BlogPostWithParse> {
  static subClassName: string = "BlogPost"

  constructor(options: BlogPostWithParse) {
    super(BlogPostEntity.subClassName, options)
  }
}

Parse.Object.registerSubclass(BlogPostEntity.subClassName, BlogPostEntity)

