import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {currentUser, getUserRoles, isAuthenticated, parseLogin, parseRegister, parseSaveImage} from "../../api/parseApi"
import {AppThunk} from "../../store/store"
import {notification} from "../notification/notificationSlice"
import * as ls from "local-storage"
import {mapParseToUser, User} from "../../api/plusApi"

export type BlogInfo = {lastVisit: number, toRead:number}
export interface ProfileState {
  isLoading: boolean
  isLogged: boolean
  user?: User
  lightMode: boolean
  navigationOpen: boolean
  error: string
  blogInfo?: BlogInfo
}

export const restoreCurrentUser = (): AppThunk => async (dispatch) => {
  try {
    const user = await currentUser()
    dispatch(loginSuccess(mapParseToUser(user, await getUserRoles(user))))
  } catch (err) {
  }
}
const initialState: ProfileState = {
  isLoading: false,
  isLogged: false,
  user: undefined,
  lightMode: ls.get("lightMode"),
  navigationOpen: ls.get("navigationOpen"),
  blogInfo: ls.get("blogInfo") ?? {lastVisit: 0, toRead: 1},
  error: ""
}

const slice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    loginStart: (state: ProfileState) => {
      state.isLoading = true
    },
    loginSuccess: (state: ProfileState, {payload}: PayloadAction<User>) => {
      state.isLoading = false
      state.isLogged = true
      state.user = payload
      state.error = ""
    },
    loginFailure: (state: ProfileState, {payload}: PayloadAction<string>) => {
      state.isLoading = false
      state.isLogged = false
      state.user = undefined
      state.error = payload
    },
    blogVisit: (state: ProfileState, {payload}: PayloadAction<BlogInfo>) => {
      state.blogInfo = payload
      ls.set("blogInfo", payload)
    },
    userUpdated: (state: ProfileState, {payload}: PayloadAction<User>) => {
      state.user = payload
    },
    switchElement: (state: ProfileState, {payload}: PayloadAction<"lightMode" | "navigationOpen">) => {
      state[payload] = !state[payload]
      ls.set(payload, state[payload])
    },
  }
})


export const login = (username: string, password: string): AppThunk => async (dispatch) => {
  try {
    dispatch(loginStart())
    const user = await parseLogin(username, password)
    const roles = await getUserRoles(user)
    dispatch(loginSuccess(mapParseToUser(user, roles)))
    dispatch(notification(`Welcome back on Recalbox ${username}!`, "success"))
  } catch (err) {
    dispatch(loginFailure(err.toString()))
    dispatch(notification(`Log in failed: ${err.toString()}`, "warning"))
  }
}

type UserImage = "background" | "avatar";
export const changeUserImage = (imageName: UserImage, image: File): AppThunk => async (dispatch) => {
  try {
    if (isAuthenticated()) {
      const user = await currentUser()
      if (!user) {
        throw new Error("You are not connected.")
      }
      const savedImage = await parseSaveImage(imageName, image)
      user.set(imageName, savedImage)
      await user.save()
      const roles = await getUserRoles(user)

      dispatch(slice.actions.userUpdated(mapParseToUser(user, roles)))
    }
  } catch (err) {
    dispatch(notification(`Unable to upload image: ${err.toString()}`, "warning"))
  }
}

export const register = (email: string, username: string, password: string): AppThunk => async (dispatch) => {
  try {
    dispatch(loginStart())
    const user = await parseRegister(email, username, password)
    const roles = await getUserRoles(user)
    dispatch(loginSuccess(mapParseToUser(user, roles)))
    dispatch(notification(`Welcome to Recalbox ${username}!`, "success"))
  } catch (err) {
    dispatch(loginFailure(err.toString()))
    dispatch(notification(`Register failed: ${err.toString()}`, "warning"))
  }
}

export const {loginStart, loginSuccess, loginFailure, switchElement, blogVisit} = slice.actions

export default slice.reducer
