import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {AppThunk} from "../../store/store"

type MessageType = "success" | "warning" | "error";

export interface Message {
  message: string;
  type: MessageType;
}

export interface MessageState {
  message: Message | null;
  count: number;
}

const initialState: MessageState = {
  message: null,
  count: 0
}

const slice = createSlice({
  name: "pushMessage",
  initialState,
  reducers: {
    messageStart: (state: MessageState, {payload}: PayloadAction<Message>) => {
      state.message = payload
      state.count++
    },
    messageStop: (state: MessageState, {payload}: PayloadAction<Message>) => {
      if (state.message?.message === payload?.message) {
        state.message = null
      }
    }
  }
})

export const notification = (message: string, type: MessageType, error?: string): AppThunk => async (dispatch) => {
  setTimeout(() => dispatch(slice.actions.messageStop({message, type})), 6000)
  dispatch(slice.actions.messageStart({message, type}))
}

export const {messageStop} = slice.actions

export default slice.reducer
