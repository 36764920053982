// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-features-blog-post-page-tsx": () => import("./../../../src/features/blog/PostPage.tsx" /* webpackChunkName: "component---src-features-blog-post-page-tsx" */),
  "component---src-features-download-all-images-page-tsx": () => import("./../../../src/features/download/AllImagesPage.tsx" /* webpackChunkName: "component---src-features-download-all-images-page-tsx" */),
  "component---src-features-download-board-page-tsx": () => import("./../../../src/features/download/BoardPage.tsx" /* webpackChunkName: "component---src-features-download-board-page-tsx" */),
  "component---src-features-download-category-page-tsx": () => import("./../../../src/features/download/CategoryPage.tsx" /* webpackChunkName: "component---src-features-download-category-page-tsx" */),
  "component---src-features-download-channel-page-tsx": () => import("./../../../src/features/download/ChannelPage.tsx" /* webpackChunkName: "component---src-features-download-channel-page-tsx" */),
  "component---src-features-download-install-page-tsx": () => import("./../../../src/features/download/InstallPage.tsx" /* webpackChunkName: "component---src-features-download-install-page-tsx" */),
  "component---src-features-download-play-page-tsx": () => import("./../../../src/features/download/PlayPage.tsx" /* webpackChunkName: "component---src-features-download-play-page-tsx" */),
  "component---src-features-download-release-notes-page-tsx": () => import("./../../../src/features/download/ReleaseNotesPage.tsx" /* webpackChunkName: "component---src-features-download-release-notes-page-tsx" */),
  "component---src-features-marketplace-category-category-page-tsx": () => import("./../../../src/features/marketplace/category/CategoryPage.tsx" /* webpackChunkName: "component---src-features-marketplace-category-category-page-tsx" */),
  "component---src-features-marketplace-item-item-page-tsx": () => import("./../../../src/features/marketplace/item/ItemPage.tsx" /* webpackChunkName: "component---src-features-marketplace-item-item-page-tsx" */),
  "component---src-features-recalbox-rgb-jamma-rgb-jamma-teasing-page-tsx": () => import("./../../../src/features/recalbox-rgb-jamma/RGBJammaTeasingPage.tsx" /* webpackChunkName: "component---src-features-recalbox-rgb-jamma-rgb-jamma-teasing-page-tsx" */),
  "component---src-features-tutorials-tutorials-page-tsx": () => import("./../../../src/features/tutorials/TutorialsPage.tsx" /* webpackChunkName: "component---src-features-tutorials-tutorials-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-badges-tsx": () => import("./../../../src/pages/badges.tsx" /* webpackChunkName: "component---src-pages-badges-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-catalog-tsx": () => import("./../../../src/pages/catalog.tsx" /* webpackChunkName: "component---src-pages-catalog-tsx" */),
  "component---src-pages-database-games-tsx": () => import("./../../../src/pages/database/games.tsx" /* webpackChunkName: "component---src-pages-database-games-tsx" */),
  "component---src-pages-database-livegames-tsx": () => import("./../../../src/pages/database/livegames.tsx" /* webpackChunkName: "component---src-pages-database-livegames-tsx" */),
  "component---src-pages-database-tsx": () => import("./../../../src/pages/database.tsx" /* webpackChunkName: "component---src-pages-database-tsx" */),
  "component---src-pages-diy-1-discover-tsx": () => import("./../../../src/pages/diy/1-discover.tsx" /* webpackChunkName: "component---src-pages-diy-1-discover-tsx" */),
  "component---src-pages-diy-2-experience-tsx": () => import("./../../../src/pages/diy/2-experience.tsx" /* webpackChunkName: "component---src-pages-diy-2-experience-tsx" */),
  "component---src-pages-diy-3-handheld-tsx": () => import("./../../../src/pages/diy/3-handheld.tsx" /* webpackChunkName: "component---src-pages-diy-3-handheld-tsx" */),
  "component---src-pages-diy-3-pc-tsx": () => import("./../../../src/pages/diy/3-pc.tsx" /* webpackChunkName: "component---src-pages-diy-3-pc-tsx" */),
  "component---src-pages-diy-3-tv-tsx": () => import("./../../../src/pages/diy/3-tv.tsx" /* webpackChunkName: "component---src-pages-diy-3-tv-tsx" */),
  "component---src-pages-diy-4-install-tsx": () => import("./../../../src/pages/diy/4-install.tsx" /* webpackChunkName: "component---src-pages-diy-4-install-tsx" */),
  "component---src-pages-diy-tsx": () => import("./../../../src/pages/diy.tsx" /* webpackChunkName: "component---src-pages-diy-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-links-tsx": () => import("./../../../src/pages/links.tsx" /* webpackChunkName: "component---src-pages-links-tsx" */),
  "component---src-pages-patreon-notapatron-tsx": () => import("./../../../src/pages/patreon/notapatron.tsx" /* webpackChunkName: "component---src-pages-patreon-notapatron-tsx" */),
  "component---src-pages-patreon-patron-tsx": () => import("./../../../src/pages/patreon/patron.tsx" /* webpackChunkName: "component---src-pages-patreon-patron-tsx" */),
  "component---src-pages-patreon-tsx": () => import("./../../../src/pages/patreon.tsx" /* webpackChunkName: "component---src-pages-patreon-tsx" */),
  "component---src-pages-plus-tsx": () => import("./../../../src/pages/plus.tsx" /* webpackChunkName: "component---src-pages-plus-tsx" */),
  "component---src-pages-recalbox-rgb-dual-manual-tsx": () => import("./../../../src/pages/recalbox-rgb-dual/manual.tsx" /* webpackChunkName: "component---src-pages-recalbox-rgb-dual-manual-tsx" */),
  "component---src-pages-recalbox-rgb-dual-order-tsx": () => import("./../../../src/pages/recalbox-rgb-dual/order.tsx" /* webpackChunkName: "component---src-pages-recalbox-rgb-dual-order-tsx" */),
  "component---src-pages-recalbox-rgb-dual-roadmap-tsx": () => import("./../../../src/pages/recalbox-rgb-dual/roadmap.tsx" /* webpackChunkName: "component---src-pages-recalbox-rgb-dual-roadmap-tsx" */),
  "component---src-pages-recalbox-rgb-dual-tsx": () => import("./../../../src/pages/recalbox-rgb-dual.tsx" /* webpackChunkName: "component---src-pages-recalbox-rgb-dual-tsx" */),
  "component---src-pages-term-of-use-tsx": () => import("./../../../src/pages/term-of-use.tsx" /* webpackChunkName: "component---src-pages-term-of-use-tsx" */),
  "component---src-pages-todo-tsx": () => import("./../../../src/pages/todo.tsx" /* webpackChunkName: "component---src-pages-todo-tsx" */),
  "component---src-pages-utils-livekickstarterstats-tsx": () => import("./../../../src/pages/utils/livekickstarterstats.tsx" /* webpackChunkName: "component---src-pages-utils-livekickstarterstats-tsx" */),
  "component---src-pages-utils-lowerthird-tsx": () => import("./../../../src/pages/utils/lowerthird.tsx" /* webpackChunkName: "component---src-pages-utils-lowerthird-tsx" */),
  "component---src-pages-utils-lowerthirdedit-tsx": () => import("./../../../src/pages/utils/lowerthirdedit.tsx" /* webpackChunkName: "component---src-pages-utils-lowerthirdedit-tsx" */)
}

